import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import SolutionType from './Components/SolutionType';
import Counter from './Components/Counter';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  
     <App/>
    //  <Counter/>
   
 
);
